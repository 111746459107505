@import "_generated-font-mixins.scss";
.filter {
  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    &.expanded {
      margin: 0 15px 15px;
    }

    & > ul {
      margin: 5px 0 10px 21px;
    }
  }

  &__border {
    padding: 15px 0 10px;
    border-top: 1px solid #ebebeb !important;

    & .filter__content {
      margin-bottom: 0;
    }
  }

  &__search {
    &__empty {
      font-size: 12px;
    }
  }
}

.arrow {
  &__down {
    &::before {
      @include icon-arrow-down;

      font-size: 16px;
    }
  }

  &__up {
    &::before {
      @include icon-arrow-up;

      font-size: 16px;
    }
  }
}

.mobile {
  &:last-of-type {
    &:not(.expanded) {
      margin-bottom: 20px;
    }
  }

  & .filter__content {
    margin: 0;
    padding: 5px 15px;
    background: #f5f5f7;

    &:not(.expanded) {
      padding: 0 15px;
      background: #fff;
    }

    & .filter__search {
      margin: 16px 16px 0 0;

      & input {
        background: #fff;
      }
    }
  }
}
