@import "_generated-font-mixins.scss";
.filters {
  width: 100%;
  border: 1px solid #ebebeb;
  border-top: 0;
  border-radius: 2px;

  &__wrap {
    &.mobile {
      width: 100%;
      height: 100vh;
    }
  }
}
