@import "_generated-font-mixins.scss";
.sort {
  display: flex;
  flex-direction: column;
  margin: 0 14px 15px;

  &.mobile {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 15px;

    background-color: #f5f5f7;

    & .sort__option {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 16px;

      &:not(:last-of-type) {
        padding-bottom: 25px;
      }

      & input {
        width: 20px;
        height: 20px;

        appearance: none;
        background-color: #fefefe;
        border: 1px solid #b1b1b1;
        border-radius: 100%;

        &:checked {
          border: 1px solid #2384ea;
          box-shadow: inset 0 0 0 3px #2b91fc;
        }
      }
    }
  }

  &__option {
    cursor: pointer;
    padding-bottom: 5px;
    font-size: 15px;

    & input {
      margin-right: 5px;
    }

    & span {
      position: relative;

      display: inline-block;

      box-sizing: border-box;
      max-width: calc(100% - 22px);
      margin-left: 2px;

      font-size: 15px;
      line-height: 17px;
      vertical-align: top;
    }
  }
}
