@import "_generated-font-mixins.scss";
.toast {
  position: fixed;
  z-index: 998;
  bottom: 20px;
  left: 20px;

  display: flex;
  gap: 12px;
  align-items: center;

  width: 350px;
  height: 48px;
  margin-right: 20px;
  padding: 12px;

  font-weight: 700;
  color: #fff;

  background-color: #2c2c2e;
  border-radius: 12px;
  box-shadow: 0 4px 12px 4px #0000000a;

  &::before {
    @include icon-confirm;

    font-size: 24px;
    line-height: 1;
    color: #fff;
  }

  &.mobile {
    bottom: 60px;
    left: 10px;
  }
}
