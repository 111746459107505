@import "_generated-font-mixins.scss";
.filters {
  &__subscribe {
    margin-bottom: 15px;
    background: #f6f6f8;
    border-radius: 4px;

    &__items {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: fit-content;
      max-width: 100%;
      margin: 5px 8px 5px 0;
      padding: 8px 8px 8px 12px;

      background-color: rgb(24 27 30 / 6%);
      border-radius: 4px;

      &__text {
        cursor: pointer;

        overflow: hidden;

        min-width: 0;
        margin-right: 8px;

        font-size: 13px;
        line-height: 15px;
        color: #333;
        text-overflow: ellipsis;
        white-space: pre;

        &.gray {
          color: #999;
        }
      }
    }

    &__showAll {
      padding-top: 10px;
    }

    &__text {
      font-size: 13px;
      line-height: 15px;
      color: #333;

      &.gray {
        color: #999;
      }
    }

    &.mobile {
      display: none;
    }

    &__title {
      font-size: 15px;
      font-weight: 700;
      line-height: 17px;
      color: #333;

      &.small {
        font-size: 12px;
        font-weight: normal;
        line-height: 1;
        color: #000;
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
    }

    &__hr {
      height: 1px;
      margin: 0 15px;
      background: #e0e0e0;
      border: 0;

      &.space {
        margin: 15px 15px 0;
      }
    }
  }
}

.icon {
  &__close {
    position: relative;
    top: 3px;

    width: 12px;
    min-width: 12px;
    height: 12px;

    text-indent: -10px;

    opacity: 0.9;

    &::before {
      @include icon-close;

      cursor: pointer;
      font-size: 30px;
      line-height: 0;
      color: #999;
    }
  }
}

.tooltip {
  &__filter {
    width: fit-content !important;
    font-size: 13px !important;
  }

  &__switcher {
    width: 200px !important;
    font-size: 13px !important;
    line-height: 1.1;
  }
}
