@import "_generated-font-mixins.scss";
.search {
  display: inline-flex;
  gap: 5px;
  align-items: center;

  font-size: 14px;
  color: #0058c5;

  &:disabled {
    color: #999 !important;
  }

  &.mobile {
    justify-content: center;

    width: 100%;
    padding: 15px;

    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    color: #2d82eb;
  }
}

.subscribe__filters {
  &::before {
    @include icon-subscribe-filters;

    font-size: 16px;
    line-height: 1;
    color: inherit;
  }
}

.notification {
  &::before {
    @include icon-notifications;

    font-size: 22px;
    line-height: 1;
    color: inherit;
  }
}
