@import "_generated-font-mixins.scss";
.modal {
  width: 390px !important;
  padding: 20px !important;

  &__title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #333;
  }

  &__input {
    position: relative;
    margin-bottom: 20px;

    & > input {
      overflow: hidden;

      width: 100%;
      padding: 8px 30px 8px 8px;

      text-overflow: ellipsis;
      white-space: nowrap;

      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  &__notifications {
    display: flex;
    gap: 10px;
    align-items: center;

    margin-bottom: 20px;

    font-size: 13px;
    color: #333;
  }

  &__actions {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 15px;

    &__save {
      width: 115px;
      padding: 8px 20px;

      line-height: 1;
      color: #fff;
      text-align: center;

      background: #0058c5;
      border-radius: 4px;

      transition: background 0.2s ease-in-out;

      &:disabled {
        background: #ccc;
      }
    }

    &__cancel {
      color: #0058c5;
    }
  }

  &.mobile {
    & .modal__actions {
      flex-direction: column;

      &__save {
        width: 100%;
        height: 40px;
      }
    }
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &::before {
    @include icon-cancel;

    font-size: 12px;
    line-height: 1;
    opacity: 0.7;
  }
}
