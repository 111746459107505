@import "_generated-font-mixins.scss";
.filters {
  &__footer {
    position: fixed;
    bottom: 0;

    display: flex;
    gap: 5px;
    align-items: center;

    width: 100%;
    padding: 5px;

    background-color: #fff;

    &__button {
      width: auto;
      height: 100%;
      max-height: 50px;
      padding: 8px 10px;

      font-size: 16px;
      text-align: center;

      border: 1px solid #333;
      border-radius: 4px;

      &.positive {
        width: 100%;
        color: #fff;
        background-color: #006bb8;
        border-color: #006bb8;
      }
    }
  }
}
